import './styles/customer-stories.scss';

type HeroCustomerStoriesProps = {
  extra_content: string
  title: string
}

export const HeroCustomerStories = ({ title, extra_content }:HeroCustomerStoriesProps) => (
  <section
    className="customer-stories-hero-section section"
    data-section="landing page hero"
  >
    <div className="container">
      <h1 className="headline has-text-centered">{ title }</h1>
      <div
        className="customer-stories-hero-container"
        dangerouslySetInnerHTML={ { __html: extra_content } }
      />
    </div>
  </section>
);
