import Link from 'components/link/link';

import './styles/customer-stories-cards.scss';

type CustomerStoriesCardsProps = {
  customers?: []
}

type CustomerProp = {
  description: string,
  image: {
    source_url: string
  },
  industry: string
  link: string,
  link_text: string,
  logo: {
    source_url: string
  }
}

const CustomerStoriesCards= ({ customers }:CustomerStoriesCardsProps) => (
  <section
    className="customer-stories-cards-section section"
    data-section="customer stories card"
  >
    <div className="container">
      <div className="columns is-multiline">
        {customers?.map((customer: CustomerProp, index) => (
          <div key={ index } className="customer-card column is-half">
            <div className="card-container columns">
              <div className="column photo">
                <picture className="customer-image">
                  <img src={ customer.image.source_url } alt="Customer Photo" />
                </picture>
              </div>
              <div className="column compnay">
                <h3 className="customer-industry">
                  <span>{customer.industry}</span>
                </h3>
                <img
                  className="customer-logo"
                  src={ customer.logo.source_url }
                  alt="Customer Logo"
                />
                <p className="customer-description">{customer.description}</p>
                <Link
                  className="customer-link"
                  to={ customer.link }
                  title={ customer.link_text }
                >
                  {customer.link_text}
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default CustomerStoriesCards;