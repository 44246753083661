import { graphql, useStaticQuery } from 'gatsby';

import Layout from 'layouts/default';

import CustomerStoriesCards from 'components/content-blocks/customer-stories-cards';
import Yoast from 'components/globals/yoast';
import { HeroCustomerStories } from 'components/hero/customer-stories';

import './styles/customer-stories.scss';

export default function CustomerStories() {
  const data = useStaticQuery(graphql`
    {
      allWordpressPage(filter: { slug: { eq: "customer-stories" } }) {
        edges {
          node {
            wordpress_id
        yoast_head_json {
          title
          description
          canonical
          og_title
          og_description
          og_image {
            url {
              source_url
            }
          }
          og_url
          og_type
          twitter_title
          twitter_description
          twitter_image {
            source_url
          }
        }
            acf {
              hero {
                title
                extra_content
              }
              customer_stories_cards {
                customers {
                  description
                  link_text
                  link
                  industry
                  image {
                    source_url
                  }
                  logo {
                    source_url
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const pageContext = data.allWordpressPage.edges[0].node.acf;
  const yoast = data.allWordpressPage.edges[0].node.yoast_head_json;
  const customerStoriesCards = pageContext.customer_stories_cards;
  const hero = pageContext.hero;

  return (
    <Layout>
      <Yoast { ...yoast } />
      <div className="customer-stories-page" data-signal={ data.allWordpressPage.edges[0].node.wordpress_id }>
        <HeroCustomerStories { ...hero } />
        <CustomerStoriesCards { ...customerStoriesCards } />
      </div>
    </Layout>
  );
}
